import { IAPILocSettings } from '../../../services/ApiCallService'
import {itemObject} from '../../../services/store/ordering/menu/categories'
import {IModifierGroup, ItemInfoState} from '../state/CartState'
import * as ApiCallService from '../../../services/ApiCallService'
import moment from 'moment-timezone'
import {IModifiers} from '../state/ModGroupState'
import { LocationScheduleDayState } from '../state/LocationSchedualState'
import { getOloSchedual } from './parsers'

export interface ILocalModifier extends IModifiers {
  global_modifier_type_id: number
  required?: number
  minimum?: number
  maximum?: number
  option?: number
  livemenu?: number
  modGroupId?: number
  modifiers?: IModifiers[]
}

export const generateNumericUid = () => {
  const timestamp = Date.now().toString() // Convert current timestamp to a string

  const random = Math.floor(Math.random() * 10000).toString() // Generate a random number between 0 and 9999 and convert to a string

  const uniqueId = timestamp + random // Concatenate the timestamp and random number

  return parseInt(uniqueId).toString().slice(-9) // Convert the resulting string to a numeric value, then to a string, and extract the first 9 characters
}

export const getMenuItemName = (item: itemObject | ItemInfoState | IModifierGroup) => {
  if (item.name_info != null && item.name_info != '') {
    return item.name_info
  }
  if (item.name != null && item.name != '') {
    return item.name
  }

  return ''
}
 
export const getMenuItemIncludedMods = (itemId: number, LocSettings: any, callback: (includedMods: IModifierGroup[]) => void) => {
  ApiCallService.ApiCall({}, `menu/item/${itemId}`, 'GET', LocSettings)
  .then((res: any) => {
    if(res?.error?.message != undefined) {
      callback([])
    } else {
      let itemdetails = res.data?.item

      if (itemdetails) {
        const temp: IModifierGroup[] = []

        itemdetails.modgroups.forEach((modgroup: IModifierGroup) => {
          modgroup.modifiers &&
            modgroup.modifiers.forEach((modifier: ILocalModifier) => {
              if (modifier.default_status) {
                const modGroupIdAdded: ILocalModifier = {
                  ...modifier,
                  modGroupId: modgroup.id,
                }
                if (!temp.some((obj) => obj.id === modGroupIdAdded.id)) {
                  temp.push(modGroupIdAdded)
                }
              }
            })
        })
        
        callback(temp)
      }
    }
  }).catch(() => {
    callback([])
  });
}

interface ScheduleEvent {
  id: number;
  type: 0 | 1;
  start_time: string;
  end_time: string;
  from_local: string;
  to_local: string;
  message: string;
}

export const determineStoreStatusForTheDay = (LocSettings:any): LocationScheduleDayState | null => {
  const date = new Date(LocSettings.location.localTime);
  const sourceOffset = date.toLocaleString('en-US', { timeZone: LocSettings.location.localTimeZone });
  const sourceDate = new Date(sourceOffset);
  const localOffset = date.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const localDate = new Date(localOffset);
  const offsetDifference = localDate.getTime() - sourceDate.getTime();

  const currentTimeDateTime = new Date(date.getTime() + offsetDifference)
  const currentTime = moment(currentTimeDateTime);
  let activeEvent: ScheduleEvent | null = null;
  const scheduleEvents = LocSettings.schedule_events;

  // Find the first active event where the current time is within the event's window
  for (const event of scheduleEvents) {
     const eventStart = moment(event.from_local).startOf('day').add(event.start_time, 'seconds');
     const eventEnd = moment(event.to_local).endOf('day').set({
        hour: parseInt(event.end_time.split(':')[0]),
        minute: parseInt(event.end_time.split(':')[1]),
        second: parseInt(event.end_time.split(':')[2]),
     });

     if (currentTime.isBetween(eventStart, eventEnd)) {
        activeEvent = event;
        break; // Stop at the first matching event
     }
  }

  if (activeEvent) {
     return {
        status: activeEvent.type,
        start_time: activeEvent.start_time,
        end_time: activeEvent.end_time,
        end_date: activeEvent.to_local,
        message: activeEvent.message
     };
  } else {
     // If no active event, fall back to OloSchedual
     let dayDetails: LocationScheduleDayState | null = null;
      const OloSchedual = getOloSchedual(LocSettings);
      const currentDay = moment.tz(LocSettings.location.localTimeZone).locale('en').format('dddd').toLowerCase()

     Object.entries(OloSchedual).forEach((day) => {
        if (day[0] === currentDay) {
           dayDetails = day[1];
        }
     });

     return dayDetails;
  }
}

function checkStoreOnline(LocSettings:any){
  const dayDetails = determineStoreStatusForTheDay(LocSettings);
  if (dayDetails && dayDetails?.status == 1) {
   const serverLocalTime = LocSettings.location.localTime;
   const serverTimeZone = LocSettings.location.localTimeZone;
   const serverTime = moment.tz(serverLocalTime, serverTimeZone);   
    const timeFormat = 'HH:mm:ss'
    const serverDate = serverTime.format('YYYY-MM-DD');
    const startTime = moment.tz(`${serverDate} ${dayDetails.start_time}`, `YYYY-MM-DD ${timeFormat} `, serverTimeZone);
    var endTime = moment.tz(`${serverDate} ${dayDetails.end_time}`, `YYYY-MM-DD ${timeFormat}`, serverTimeZone);
    var timeValidation = serverTime.isBetween(startTime, endTime);
    if (dayDetails?.end_date) {
      endTime = moment.tz(`${dayDetails.end_date.split(' ')[0]} ${dayDetails.end_time}`, `YYYY-MM-DD ${timeFormat}`, serverTimeZone);           
      timeValidation = serverTime.isBetween(startTime, endTime);
    }
    const checkBeforeOpen = serverTime.isBefore(startTime)
    if (checkBeforeOpen || !timeValidation) {
      return false
     }
  } else {
    return false
  }
  return true
}

export function isStoreOnline(LocSettings: IAPILocSettings, callback: (isOnline: boolean) => void) { 
  ApiCallService.ApiCall({}, `/mobile/settings`, 'GET', LocSettings)
  .then((res: any) => {
    callback(LocSettings?.olo?.orders_before_open == 1 || res?.data?.olo?.allow_ordering && checkStoreOnline(res.data));
  })
  .catch((err) => {
    callback(false);
  });
}

interface iTimeSlot {
  LocSettings:IAPILocSettings,
  current_date:string,
  isToday:boolean
}

export function GetAvailableTimeSlote(iTimeSlot: iTimeSlot, callback: (slots: string[]) => void) { 
  ApiCallService.ApiCall({current_datetime:iTimeSlot.current_date,isToday:iTimeSlot.isToday?1:0}, `/mobile/get-avialable-ticket-slots`, 'GET', iTimeSlot.LocSettings)
  .then((res: any) => {
    callback(res?.data);
  })
  .catch((err) => {
    callback([]);
  });
}

export const checkInTime = (startTime:String|null, endTime:String|null, timeZone: string, localTime: string) => {
  if(!startTime || !endTime || startTime == endTime)  return true
  //console.log(startTime,'=>',moment.utc().format('HH:mm'),'=>',endTime)
  function timeToMinutes(time:String) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }
  const currentTime = moment.tz(localTime, timeZone).format('HH:mm')
  const minutesStartTime = timeToMinutes(startTime);
  const minutesEndTime = timeToMinutes(endTime);
  const minutesCurrentTime = timeToMinutes(currentTime);
  if(minutesStartTime>minutesEndTime){
    return minutesCurrentTime >= minutesStartTime || minutesCurrentTime <= minutesEndTime; 
  }else{
    return minutesCurrentTime >= minutesStartTime && minutesCurrentTime <= minutesEndTime; 
  }
} 

export const textLimit = (text:string, limit:number = 55, append:string = '...') => {
  if (text.length <= limit) {
    return text;
  }

  return `${text.substring(0, limit)}${append}`
}
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { getOloSchedual, getOloSettings } from '../common/parsers'
import moment from 'moment-timezone'
import { LocationScheduleDayState } from '../state/LocationSchedualState'
import {
   setIsLocationScheduleAvailable,
   setLocationScheduleToday,
} from '../../../services/store/LocationSchedual'
import { FormattedMessage, useIntl } from 'react-intl'
import { determineStoreStatusForTheDay } from '../common/utilities'

export const StoreClosedBanner = () => {
   const intl = useIntl()
   const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
   const [orderAlertShow, setOrderAlertShow] = useState<boolean>(false)
   const [nextScheduleDay, setNextScheduleDay] = useState<string | null>(null)
   const [nextScheduleDayOpenTime, setNextScheduleDayOpenTime] = useState<string | null>(null)
   const [openTill, setOpenTill] = useState<string>('')
   const [closeMessage, setCloseMessage] = useState<string>('')

   const dispatch = useDispatch<AppDispatch>()

   const serverLocalTime = LocSettings.location.localTime;
   const serverTimeZone = LocSettings.location.localTimeZone;

   const serverTime = moment.tz(serverLocalTime, serverTimeZone);   
   const currentDay = serverTime.format('dddd').toLowerCase()
   const timeFormat = 'HH:mm:ss'
   const oloSettings = getOloSettings(LocSettings)
   const OloSchedual = getOloSchedual(LocSettings)
   let nextDayDetails: LocationScheduleDayState | null = null

   const findSchedule = (currentDay: string) => {
      if (OloSchedual) {

         const dayDetails = determineStoreStatusForTheDay(LocSettings);
         if (dayDetails && dayDetails?.status == 1) {
            const serverDate = serverTime.format('YYYY-MM-DD');
            const startTime = moment.tz(`${serverDate} ${dayDetails.start_time}`, `YYYY-MM-DD ${timeFormat} `, serverTimeZone);
            var endTime = moment.tz(`${serverDate} ${dayDetails.end_time}`, `YYYY-MM-DD ${timeFormat}`, serverTimeZone);
        
            var timeValidation = serverTime.isBetween(startTime, endTime);
             
            if (dayDetails?.end_date) {
               endTime = moment.tz(`${dayDetails.end_date.split(' ')[0]} ${dayDetails.end_time}`, `YYYY-MM-DD ${timeFormat}`, serverTimeZone);           
               timeValidation = serverTime.isBetween(startTime, endTime);
            }

            const checkBeforeOpen = serverTime.isBefore(startTime)
            if (timeValidation) {
               setOpenTill(endTime.clone().tz(moment.tz.guess()).format('hh:mm A'))
            }
            if (checkBeforeOpen || timeValidation) {
               dispatch(setIsLocationScheduleAvailable(true))
               dispatch(setLocationScheduleToday(true))
            }
            if (checkBeforeOpen) {
               setNextScheduleDay('today')
               setNextScheduleDayOpenTime(startTime.format('hh:mm A'))
               dispatch(setIsLocationScheduleAvailable(true))
               setOrderAlertShow(true)
            } else if (!timeValidation) {
               setOrderAlertShow(true)
               findNextSchedule(currentDay, 1)
            }
         } else {
            if (dayDetails && dayDetails?.status == 0 && dayDetails?.end_date) {
               setCloseMessage(dayDetails?.message ?? "");
               setOrderAlertShow(true)
            } else {
               setOrderAlertShow(true)
               findNextSchedule(currentDay, 1)
            }
         }
      }
   }
   const findNextSchedule = (currentDay: string, daycount: number) => {

      const nextDay = moment().locale("en").add(daycount, 'days').format('dddd')
      Object.entries(OloSchedual).map((day) => {
         if (day[0] == nextDay.toLowerCase()) {
            nextDayDetails = day[1]
         }
      })
      if (nextDayDetails && nextDayDetails.status == 1) {
         const nextScheduleDay = daycount === 1 ? 'Tomorrow' : serverTime.clone().add(daycount, 'days').format('dddd');
         const nextScheduleDayOpenTime = moment
           .tz(`${serverLocalTime.split(' ')[0]} ${nextDayDetails.start_time}`, `YYYY-MM-DD ${timeFormat}`, serverTimeZone)
           .clone()
           .tz(moment.tz.guess())
           .format('hh:mm A');
     
         setNextScheduleDay(nextScheduleDay)
         setNextScheduleDayOpenTime(nextScheduleDayOpenTime)
         setOrderAlertShow(true)
         if ((oloSettings?.schedule_orders_days ?? 1) >= daycount) {
            dispatch(setIsLocationScheduleAvailable(true))
         }
      } else {
         findNextSchedule(currentDay, daycount + 1)
      }
   }

   useEffect(() => {
      findSchedule(currentDay)
   }, [])

   return (
      <>{orderAlertShow && (
         <span className='text-danger fw-bold'>
            {nextScheduleDayOpenTime == null && nextScheduleDay == null ? (
               <>
                  {LocSettings.location.location_name}{' '}
                  <FormattedMessage
                     id='olo.store.currently_not_open'
                     defaultMessage='is currently not open'
                  />
                  <div>
                     {closeMessage}
                  </div>
               </>
            ) : (
               <>
                  <FormattedMessage
                     id='olo.store.will_open_at'
                     defaultMessage='is currently not open and will open at'
                  />{' '}
                  {nextScheduleDayOpenTime} {nextScheduleDay && nextScheduleDay != "today" && (
                     intl.formatMessage({ id: 'common.on' }) && ' ' && nextScheduleDay
                  )}
               </>
            )}
         </span>
      )}
         {!orderAlertShow && openTill == " " && (
            <span className='text-success fw-bold' >
               <FormattedMessage
                  id='olo.store.open'
                  defaultMessage='Open'
               />
            </span>
         )}
         {!orderAlertShow && openTill.trim() != "" && (
            <span className='text-success fw-bold' >
               <FormattedMessage
                  id='olo.store.open_till'
                  defaultMessage='Open till'
               />{' '} {openTill}
            </span>
         )}
      </>
   )
}
